import * as React from 'react'
import Layout from '../components/layout'
import WhatIs from '../components/whatIs'
import '../styles/homepage.scss'

const WhatIsSouthern360 = () => {
  return (
      <Layout nextBottomSlideBtn="Next" nextBottomSlideBtnLink="/Benchmarking" id="whatIsSouthern">
        <div style={{ padding: "50px 5vw 0 5vw", margin: "-40px -5vw 0 -5vw" }} id="whatIs"> 
            <h1>What is Southern 360?</h1>
            <p className="textMedSize fade">Southern 360 is a new way to work with our family of businesses. We've packaged our energy expertise into solutions to help your organization to achive sustainability and resiliency goals.</p>
            <div className="imageSectionWLines">
              <WhatIs 
                keyNum = '1'
                title = "Know Where You're Going:"
                desc = "Goals"
              />
              <WhatIs 
                keyNum = '2'
                title = "Know Where You Stand:"
                desc = "Benchmarking"
              />
              <WhatIs 
                keyNum = '3'
                title = "Know What To Do:"
                desc = "Prioritization"
              />
              <WhatIs 
                keyNum = '4'
                title = "Know How To Do It:"
                desc = "Implementation"
              />
            </div>
        </div>
      </Layout>
  )
}

export default WhatIsSouthern360
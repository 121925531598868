import React from 'react'

import goals from "../images/whatIs/Goals.png";
import line1 from "../images/whatIs/line1.png";

import benchMarking from "../images/whatIs/Benchmarking.png";
import line2 from "../images/whatIs/line2.png";

import prioritization from "../images/whatIs/Prioritization.png";
import line3 from "../images/whatIs/line3.png";

import implementation from "../images/whatIs/Implementation.png";


const whatIs = ( data ) => {
	let num = data.keyNum;
	let graphic="";
	let line="";

	switch(num){
		case '1':
			graphic = goals
			line = line1
			break;
		case '2':
			graphic = benchMarking
			line = line2
			break;
		case '3':
			graphic = prioritization
			line = line3
			break;
		case '4':
			graphic = implementation
			break;
		default:
			graphic = goals
			break;
	}
	if(line !== ""){
		return (
			<div className="section" style={{ animation: `fadeIn .75s ease-in-out forwards ${num - 1}s`, opacity: 0 }}>
		        <div className="images">
		          <img
		            alt="southern company logo"
		            src={graphic}
		            style={{ animation: `fadeIn .75s ease-in-out forwards ${num - 1}s`, opacity: 0 }}
		          />
		          <div className={`line line`+num}>
		            <img
		              alt="southern company logo"
		              src= {line}
		              style={{ animation: `fadeIn .75s ease-in-out forwards ${num - .5}s`, opacity: 0 }}
		            />
		          </div>  
		        </div>  
				<div className="content">
			        <div className="text">    
			          <h2>{data.title}</h2>
			          <h3>{data.desc}</h3>
			        </div>
			  	</div>
		  	</div>
	    
	  	)
	}else{
		return (
			<div className="section" style={{ animation: `fadeIn .75s ease-in-out forwards ${num - 1}s`, opacity: 0 }}>
		        <div className="images">
		          <img
		            alt="southern company logo"
		            src={graphic}
		            style = {{animation: `fadeIn .75s ease-in-out forwards ${num - 1}s`, opacity: 0}}
		          /> 
		        </div>  
				<div className="content">  
		          <h2>{data.title}</h2>
		          <h3>{data.desc}</h3>
			  	</div>
		  	</div>
	    
	  	)
	}
}
export default whatIs